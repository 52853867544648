<template>
  <div> 
  <div>
      <div class="sp-area">
        <div class="container-fluid">
          <vue-easy-lightbox
            :visible="visible"
            :imgs="imgs"
            :index="index"
            @hide="handleHide"
          >
          </vue-easy-lightbox>
          <div class="sp-nav">
            <div class="row">
              <div class="col-lg-4">
                <div class="sp-img_area">
                  <div>
                    <hooper group="group1">
                      <slide>
                        <button @click="showSingle(imag1)">
                          <img :src="imag1" alt="Product Image" />
                        </button>
                      </slide>
                      <slide>
                        <button @click="showSingle(imag2)">
                          <img :src="imag2" alt="Product Image" />
                        </button> </slide
                      ><slide>
                        <button @click="showSingle(imag3)">
                          <img :src="imag3" alt="Product Image" />
                        </button> </slide
                      ><slide>
                        <button @click="showSingle(imag4)">
                          <img :src="imag4" alt="Product Image" />
                        </button>
                      </slide>
                    </hooper>
                    <hooper group="group1" :itemsToShow="4" :centerMode="true">
                      
                      <slide v-for="image in auctionifos" :key="image">
                        <div>
                          <img :src="imag1" alt="Product Image" />ima
                        </div>
                      </slide>
                      <slide>
                        <div>
                          <img :src="imag1" alt="Product Image" />
                        </div>
                      </slide>
                      <slide>
                        <div>
                          <img :src="imag2" alt="Product Image" />
                        </div>
                      </slide>
                      <slide>
                        <div>
                          <img :src="imag3" alt="Product Image" />
                        </div>
                      </slide>
                      <slide>
                        <div>
                          <img :src="imag4" alt="Product Image" />
                        </div>
                      </slide>

                      <hooper-pagination
                        slot="hooper-addons"
                      ></hooper-pagination>
                    </hooper>
                  </div>
                </div>
              </div>
              <div class="col-lg-8">
                <div class="sp-content">
                  <div class="sp-heading">
                    <h5><a href="#">{{ auctionifos.brandName}}  {{ auctionifos.modelName }} {{ auctionifos.manYear }}</a></h5>
                  </div>
                  <div>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text ever since the 1500s, when an unknown
                      printer took a galley of type and scrambled it to make a
                      type specimen book.
                    </p>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <h6>Details</h6>
                      <div class="tablerounededCorner">
                        <table class="table table-striped roundedTable">
                          <!-- <tr>
                            <th>Heading 1</th>
                            <th>Heading 2</th>
                          </tr> -->
                          <!-- <tr>
                            <td>Body Type</td>
                            <td>N/A</td>
                          </tr> -->
                          <tr>
                            <td>Brand Name</td>
                            <td>{{ auctionifos.brandName}}</td>
                          </tr>
                          <tr>
                            <td>Model Name</td>
                            <td>{{ auctionifos.modelName}}</td>
                          </tr>
                          <tr v-if="!userinfo">
                            <td>Vehicle Registration</td>
                            <td>{{ auctionifos.vehRegNo | truncate(5)}} ****</td>
                          </tr>
                          <tr v-if="userinfo">
                            <td>Vehicle Registration</td>
                            <td>{{ auctionifos.vehRegNo }}</td>
                          </tr>
                          <!-- <tr>
                            <td>Motor No.</td>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>Exterior colour</td>
                            <td>N/A</td>
                          </tr> -->
                          <tr>
                            <td>Mileage</td>
                            <td>{{ auctionifos.kmClocked}} KM</td>
                          </tr>
                          <tr>
                            <td>Year Of Make</td>
                            <td>{{ auctionifos.manYear}}</td>
                          </tr>
                          <!-- <tr>
                            <td>Fuel</td>
                            <td>N/A</td>
                          </tr> -->
                        </table>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <h6>Bid Information</h6>
                      <div class="bid-info-wrap">
                        <div>
                          <div class="bid-info">
                            <div class="bid-info-left">Current Price</div>                            
                            <div class="bid-info-right" v-if="auctionifos.latestBidAmount == null"> ₹ 0 </div>
                            <div class="bid-info-right" v-else>  ₹ {{ auctionifos.latestBidAmount }}</div>
                          </div>
                          <div class="bid-info">
                            <div class="bid-info-left">Time Remaining</div>
                            <div class="bid-info-right">{{ auctionifos.auxMaxExtDate | truncate(10) }}</div>
                          </div>
                          <div class="bid-info">
                            <div class="bid-info-left">End Date</div>
                            <div class="bid-info-right">{{ auctionifos.auxMaxExtDate | truncate(10)}}</div>
                          </div>
                          <div class="bid-info">
                            <div class="bid-info-left">Location</div>
                            <div class="bid-info-right">{{ auctionifos.locName }}</div>
                          </div>
                          <div class="bid-info">
                            <div class="bid-info-left">Current Bids</div>
                            <div class="bid-info-right">{{ auctionifos.bidzCount}}</div>
                          </div>
                        </div>
                        <b-button v-b-modal.modalbidz class="detailsBtn"  @click=" sendInfo(auctionifos)">BID NOW</b-button>
                                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-modal
      id="modalbidz"
      ref="modal"  hide-footer  v-if="userinfo"     
    >
      <form ref="form">
        <p for="bidamount" class="bidamountzz" v-if="selectedUser.latestBidAmount == null">Latest Bid Amount:   ₹  0  </p>
        <p for="bidamount" class="bidamountzz" v-else>Latest Bid Amount:   ₹  {{ selectedUser.latestBidAmount }}  </p>
         <input type="test" class="form-control" id="bidamount" v-model="bid" placeholder="Enter your bid amount" name="bidamont" style="width:100%;">
        <button type="button"  class="btn subBtnzz" @click="bidentry(selectedUser)" style="width: 100%;margin-top: 10px;">SUBMIT</button>
                      <div class="form-group" v-if="message == 'Success'"> 
                      <div  class="alert alert-warning" role="alert" >{{message}}</div>
                       
                      </div>
                      <div class="form-group" v-else-if="message"> 
                      <div  class="alert alert-warning" role="alert">{{message}}</div>
                      </div>
      </form> 
      
    </b-modal>


    <b-modal
      id="modalbidz"
      ref="modal"  hide-footer  v-else-if="!userinfo"     
    >
      <form ref="form">
       
        <div>
                                    <p for="bidamount" class="bidamountzz">Login to BID </p>
                                 </div>
                                
                                <!-- <input type="test" class="form-control" id="bidamount" v-model="bid" placeholder="Enter your bid amount" name="bidamont" style="width:100%;"> -->
                            
                        <button type="submit"  class="btn subBtnzz" @click="gotologin()" style="width: 100%;margin-top: 10px;">Go To Login</button>

                    
      </form>   
    </b-modal>
        </div>
      </div>

      <section
        style="padding: 80px 0px; background-color: #f1f1f1; margin-top: 80px"
      >
        <selectioncontainer></selectioncontainer>
        <!-- .container -->
      </section>
    </div>


  </div>
</template>
<script>
import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
import "hooper/dist/hooper.css";
import userService from '../services/user.service';
import user from '../models/localstorage'
let imag1 = "/assets/images/car1.jpg";
let imag2 = "/assets/images/car2.jpg";
export default {
 
         components: {
    Hooper,
    Slide,
    HooperPagination,
  },

 data() {
    return {
      imgs: "", // Img Url , string or Array of string
      visible: false,
      index: 0, // default: 0
      imag1: imag1,
      imag2: imag2,
      imag3: imag1,
      imag4: imag2,
      details:'',
      auctionifos:'',
      bid:'',
      message:'',
      selectedUser:'',
      userinfo:''
      
    };
  },
  created() {
    console.log( this.$route.params.url_key);
 // let id = this.$route.params.url_key;
 this.userinfo = this.currentUser;
     console.log(this.userinfo);
},

computed: {
        currentUser() {
        
      return this.$store.state.auth.user;

    }
},
  filters: {
        truncate: function(data,num){
            const reqdString = data.split("").slice(0, num).join("");
            return reqdString;
        }
    },
   mounted() {
     

        let id =this.$route.params.url_key;
          this.getauctionbyname(id);

          
          
  },
    props: ["changed_url"],
watch : {
     '$route.query.search'() {
       console.log(this.$route.query.search);
       let id =this.$route.query.search;
                this.getauctionbyname(id);
            }

     
               },
  methods: {
    gotologin(){
         this.$router.push('/login').catch(()=>{"login"});

      },
       sendInfo(auctionifos) {        
        this.selectedUser = auctionifos;
    },
      getauctionbyname(id){
          
         
          if( !id || id =='' ){
              this.$router.push('/Auction').catch(()=>{"auctionbyname"});
          }
           
         userService.getauctionbyname(id)
        .then(response => {
          this.auctionifos = response.data.Data[0];
        })
        .catch(e => {
          console.log(e);
          this.$router.push('/Auction').catch(()=>{"details/auctionbyname"});
        });
      },
       bidentry(){
         console.log(parseInt(this.bid) );
         console.log(this.selectedUser.aucid);
       
       
        if( user[0].customerId&& this.bid !=='' && this.bid !== null){
          let cusid = user[0].customerId
        let bidamount = parseInt(this.bid);
        userService.addbid(this.selectedUser.aucid,cusid,bidamount).then(response =>{
          console.log(response.data.Message);
         this.message = response.data.Message
         this.getauctionbyname() 
        }).catch(error => {
              this.message =
                (error.response && error.response.data.Message) ||
                error.message ||
                error.toString();
                })  

        }
        else
        {
          this.message ='Field is requied'
        }
        

      },
    showSingle(image) {
      this.imgs = image;

      this.show();
    },
    show() {
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    //  getvehbyId(id){
    //      userService.getvehbyId(id)
    //     .then(response => {
    //       this.currentuserinfo = response.data.Data[0];
    //     })
    //     .catch(e => {
    //       console.log(e);
    //     });
    //   },
     
  },
  
};
</script>
<style scoped>
.hooper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.subBtnzz
{
      background-image: linear-gradient(to bottom right, #af0000, #ff3a3a);
    color: white;
    font-weight: 600;
}
.bidamountzz
{
  font-size: 20px;
    font-weight: 600;
}
.heart-stroke {
  fill: none;
  stroke: #ddd;
  stroke-width: 2px;
  opacity: 1;
  transform-origin: center center;
}
.button-bidzzz.active .heart-stroke {
  opacity: 0;
}
.heart-full {
  opacity: 0;
  transform-origin: 50% 50%;
}
.button-bidzzz.active .heart-full {
  opacity: 1;
}
.heart-lines {
  stroke-width: 2px;
  display: none;
}

.button-bidzzz:not(.active):hover .heart-stroke {
  -webkit-animation: pulse 1s ease-out infinite;
          animation: pulse 1s ease-out infinite;
}

.button-bidzzz.animate .heart-full {
  -webkit-animation: heart 0.35s;
          animation: heart 0.35s;
}
.button-bidzzz.animate .heart-lines {
  -webkit-animation: lines 0.2s ease-out forwards;
          animation: lines 0.2s ease-out forwards;
  display: block;
}

@-webkit-keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}

@keyframes lines {
  0% {
    stroke-dasharray: 6;
    stroke-dashoffset: 16;
  }
  100% {
    stroke-dasharray: 13;
    stroke-dashoffset: 18;
  }
}
@-webkit-keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heart {
  0% {
    transform: scale(1);
    transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    transform: scale(1.2);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  35% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  75% {
    transform: scale(1.1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  100% {
    transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes pulse {
  0% {
    opacity: 1;
    transform-origin: center center;
    transform: scale(1);
  }
  50% {
    opacity: 0.6;
    transform: scale(1.15);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

</style>
